import React from 'react';

// Layout Imports
import Default from '../layouts/Default/Default';
import SEO from '../layouts/SEO/SEO';

// Component Imports
import PageHeader from '../components/shared/PageHeader/PageHeader';
import Section from '../components/shared/Section/Section';
import ImageWithContent from '../components/shared/ImageWithContent/ImageWithContent';

// Image Assets
import Parky from '../assets/images/content/parky.png';
import Avatar from '../assets/images/content/youtube-avatar.jpg';

const About = (props) => {
  return (
    <Default
      location={props.location}
      title='About'
    >
      <SEO
        title='About'
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <PageHeader
        heading='About'
        description="In late 2010, Parky created a new YouTube channel; determined to share his sarcastic outlook on video games with anyone who'd listen. Several years later, and after many adventures, Parky is still sharing his awkward commentary with thousands of people around the world"
      />

      <Section className='section--chevron section--white section--blue-after' heading="The channel" accent>
        <ImageWithContent
          image={Avatar}
          imageDescription='ParkyGames YouTube avatar'
          reverse
        >
          <p className='paragraph'>The channel started on 26<sup>th</sup> December 2010 with a proper oldie; 'Let's Play Fable 3' - few would say was a classic, but it was definitely the start of something special.</p>
          <p className='paragraph'>The early focus of the channel was on RPG-Fantasy games, with themes of heavy storytelling, and of course, a dash of Parky's 'classic charm'. Many friends were made along the way, with the fan-favourite series of Minecraft 'Jungle Survival' and even more series featuring familiar faces such as CrazyKipps.</p>
          <p className='paragraph'>Spore had always been a childhood favourite - and made a big return to the limelight on YouTube with several popular series on the channel - a game that must never be forgotten!</p>
          <p className='paragraph'>Looking forward, it is always in the forefront of Parky's mind to create new content and to show just how much the channel and his viewers mean... a very awkward amount.</p>
        </ImageWithContent>
      </Section>

      <Section className='section--blue' heading='The person'>
        <ImageWithContent
          image={Parky}
          imageDescription='Parky himself'
        >
          <p className='paragraph white'>Outside of YouTube, I work as a professional Front-end Web Developer. It has always been my dream to create YouTube videos for a large community of viewers, and one day I hope to be able to do it full time! Thank you for helping me achieve my dream!</p>
          <p className='paragraph white'>I'm a huge fan of the wacky and weird when it comes to gaming, especially the huge variety of strange Indie titles I manage to dig out of the Steam Store and beyond. You should probably check out my <a className='link white' href='https://www.youtube.com/playlist?list=PLp7TeQlSA_ZuG0qxkcvvKQuLU3IJDZLUp'>Parky's Shorts Series</a> for this!</p>
          <p className='paragraph white'>I'm also a huge fan of playing games that bring back a real feeling of nostalgia; this is probably why I love Spore far too much, and also why I'm most well known on YouTube for all of my <a className='link white' href='https://www.youtube.com/user/ParkyGames/playlists?view=50&sort=dd&shelf_id=4'>Spore content</a>!</p>
          <p className='paragraph white'>If you'd like a daily look into my life, it would definitely be worth following me on <a className='link white' href='https://www.instagram.com/parky_games/'>Instagram</a>, <a className='link white' href='https://www.facebook.com/parkygames'>Facebook</a> or <a className='link white' href='https://twitter.com/parkygames'>Twitter</a>.</p>
        </ImageWithContent>
      </Section>
    </Default>
  );
};

export default About;